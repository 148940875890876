import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./StripeSettings.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import SettingTextField from "../../components/SettingTextField";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import RestaurantHeader from "../../components/RestaurantHeader";

let table_type_list = [
	{ label: "Ordered Table", value: "ordered" },
	{ label: "Layout Table", value: "layout" },
];

const StripeSettings = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [restaurantId, setRestaurantId] = useState(
		location.pathname.split("/").length == 4
			? location.pathname.split("/")[2]
			: ""
	);

	const [loading, setLoading] = useState(false);
	const [restaurantDetail, setRestaurantDetail] = useState(null);
	const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
	const [destinationAccountId, setDestinationAccountId] = useState("");
	const [applicationFeePercent, setApplicationFeePercent] = useState("");
	const [msgAlert, setMsgAlert] = useState({
		open: false,
		message: "",
		msgType: "error",
	});

	useEffect(() => {
		let data = restaurantDetail;
		let data1 = {
			destinationAccountId: destinationAccountId ? destinationAccountId : null,
			applicationFeePercent: applicationFeePercent
				? applicationFeePercent
				: null,
		};

		if (
			data?.destinationAccountId !== data1.destinationAccountId ||
			data?.applicationFeePercent !== data1.applicationFeePercent
		) {
			setSaveBtnDisabled(false);
		} else {
			setSaveBtnDisabled(true);
		}
	}, [destinationAccountId, applicationFeePercent]);

	useEffect(() => {
		getRestaurantData();
	}, []);

	const getRestaurantData = () => {
		setLoading(true);
		Api.getRestaurantData(restaurantId).then((response) => {
			if (response.success) {
				let resData = response.data;
				console.log({ resData });
				setDestinationAccountId(
					resData?.paymentInfo?.destinationAccountId
						? resData?.paymentInfo?.destinationAccountId
						: ""
				);
				setApplicationFeePercent(
					resData?.paymentInfo?.applicationFeePercent
						? resData?.paymentInfo?.applicationFeePercent
						: ""
				);
				setRestaurantDetail(resData);
			} else {
				setMsgAlert({ open: true, message: response.msg, msgType: "error" });
			}
			setLoading(false);
		});
	};

	const onSave = () => {
		if (destinationAccountId == "") {
			setMsgAlert({
				open: true,
				message: "Stripe Connect ID is Required",
				msgType: "error",
			});
			return;
		}

		if (applicationFeePercent == "") {
			setMsgAlert({
				open: true,
				message: "Application Fee Amount is Required",
				msgType: "error",
			});
			return;
		}

		let data = {
      paymentInfo: {
        destinationAccountId: destinationAccountId ? destinationAccountId : null,
        applicationFeePercent: applicationFeePercent ? applicationFeePercent : null,
      }
		};

		let datas = JSON.stringify(data);

		setLoading(true);
		Api.updateRestaurant(datas, restaurantId).then((response) => {
			console.log({ response });
			if (response.success) {
				setRestaurantDetail({
					...restaurantDetail,
					...data,
				});
				setSaveBtnDisabled(true);
				setMsgAlert({ open: true, message: response.msg, msgType: "success" });
			} else {
				setMsgAlert({ open: true, message: response.msg, msgType: "error" });
			}
			setLoading(false);
		});
	};

	return (
		<Container
			page={"restaurants"}
			searchVal={""}
			onSearch={() => null}
			searchLabel=''
		>
			{loading ? (
				<Loader height={{ height: window.innerHeight - 150 }} />
			) : (
				<Grid container item xs={12}>
					<Grid item xs={12} mb={2}>
						<RestaurantHeader
							title={"Stripe Settings"}
							onBackBtnClick={() => navigate("/restaurant/" + restaurantId)}
						/>
					</Grid>
					<Grid item xs={12} className={styles.mainView} px={3} pt={2}>
						<Grid item xs={12} md={5.8}>
							<Box mb={1}>
								<Typography className={styles.textInputLabel}>
									Stripe Connect ID
								</Typography>
							</Box>
							<Box mb={1}>
								<SettingTextField
									page={"stripeSettings"}
									value={destinationAccountId}
									setValue={setDestinationAccountId}
									border={"1px solid #AAA"}
									placeholder={""}
									required={true}
									variant={"filled"}
								/>
							</Box>

							<Box mb={1}>
								<Typography className={styles.textInputLabel}>
									Application Fee Amount
								</Typography>
							</Box>
							<Box mb={1}>
								<SettingTextField
									page={"stripeSettings"}
									value={applicationFeePercent}
									setValue={setApplicationFeePercent}
									border={"1px solid #AAA"}
									placeholder={""}
									required={true}
									variant={"filled"}
								/>
							</Box>
							<Grid container item xs={12} mt={2}>
								<Grid item xs={6}>
									<Box className={styles.btnMainView}>
										<Button
											type='button'
											disabled={saveBtnDisabled}
											className={styles.saveBtn}
											sx={{
												opacity: saveBtnDisabled ? 0.2 : 1,
												"&:hover": {
													backgroundColor: "#000 !important",
												},
											}}
											onClick={saveBtnDisabled ? null : onSave}
										>
											Save
										</Button>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
			<AlertMsg
				msgAlert={msgAlert}
				onCloseAlertMsg={() =>
					setMsgAlert({ open: false, message: "", msgType: "error" })
				}
			/>
		</Container>
	);
};

export default StripeSettings;
