import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./CustomRestaurantInfo.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import UploadLogo from "../../components/UploadLogo";
import SettingTextField from "../../components/SettingTextField";
import SettingFileInput from "../../components/SettingFileInput";
import SwitchInput from "../../components/SwitchInput";
import SocialMedia from "../../components/SocialMedia";
import SettingSelectField from "../../components/SettingSelectField";
import RestaurantHeader from "../../components/RestaurantHeader";
import PortalSettingsColorInput from "../../components/PortalSettingsColorInput";

import {
  APP_TYPE_BOTH,
  APP_TYPE_FOODO,
  APP_TYPE_GOPOSH,
} from "../../constants";

import { ReactComponent as InfoIcon } from "../../assets/images/rest_info.svg";

let RESTLIST = ["6509e4d7d1197a7aa90df7df"]; //rokka

const InfoList = {
  printLogo: "Upload the logo that needs to be printed in receipts",
  mainHeaderBg:
    "Header background image that needs to be shown on ar 3d page top section",
  headerBg: "",
  arModelBg: "Background image shown at the 3d model page",
  bottomMenuIcon:
    "Icon image shown for the menu button in 3d page bottom centre section",
  menuSectionBg: "Background image for bottom menu section in ar 3d page",
  prevIcon:
    "Icon image shown for the previous button in 3d page bottom menu section",
  nextIcon:
    "Icon image shown for the next button in 3d page bottom menu section",
  onlineOrder: "Toggle option for user to place order online",
  tableOrder: "Toggle option for user to place table order",
  tablePayment:
    "Toggle option for user to make payment for table and online order",
  wifiAvailable: "Toggle option to show wifi icon in 3d menu page for user",
};

const CustomRestaurantInfo = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [restaurantDetail, setRestaurantDetail] = useState(null);
  const [headerBg, setHeaderBg] = useState("");
  const [arModelBg, setArModelBg] = useState("");
  const [bottomMenuIcon, setBottomMenuIcon] = useState("");
  const [printLogo, setPrintLogo] = useState("");
  const [nextIconVal, setNextIconVal] = useState("");
  const [prevIconVal, setPrevIconVal] = useState("");
  const [menuSectionBgVal, setMenuSectionBgVal] = useState("");

  const [menuItemFontBg, setMenuItemFontBg] = useState("");
  const [menuItemFontColor, setMenuItemFontColor] = useState("");
  const [menuItemFontName, setMenuItemFontName] = useState("");
  const [menuItemFontFile, setMenuItemFontFile] = useState("");

  const [modalBgColor, setModalBgColor] = useState("");
  const [modalFontColor, setModalFontColor] = useState("");
  const [userFormDataBg, setUserFormDataBg] = useState("");
  const [userFormDataLabelColor, setUserFormDataLabelColor] = useState("");
  const [userFormDataFontColor, setUserFormDataFontColor] = useState("");
  const [buttonBg, setButtonBg] = useState("");
  const [buttonTextBg, setButtonTextBg] = useState("");

  const [menuHeaderBg, setMenuHeaderBg] = useState("");
  const [categoryUnderlineColor, setCategoryUnderlineColor] = useState("");
  const [itemPriceFontColor, setItemPriceFontColor] = useState("");
  const [itemPriceBgColor, setItemPriceBgColor] = useState("");
  const [arViewBgColor, setArViewBgColor] = useState("");

  const [menuListBgColor, setMenuListBgColor] = useState("");
  const [menuListFontColor, setMenuListFontColor] = useState("");
  const [menuListCatFontColor, setMenuListCatFontColor] = useState("");
  const [menuListCatBgColor, setMenuListCatBgColor] = useState("");
  const [menuListSelectedCatFontColor, setMenuListSelectedCatFontColor] =
    useState("");
  const [menuListSelectedCatBgColor, setMenuListSelectedCatBgColor] =
    useState("");

  const [menuDetailPopupItemFontColor, setMenuDetailPopupItemFontColor] =
    useState("");
  const [
    menuDetailPopupItemPriceFontSize,
    setMenuDetailPopupItemPriceFontSize,
  ] = useState("");
  const [menuDetailPopupItemBgColor, setMenuDetailPopupItemBgColor] =
    useState("");
  const [menuDetailPopupModifierBgColor, setMenuDetailPopupModifierBgColor] =
    useState("");
  const [
    menuDetailPopupModifierFontColor,
    setMenuDetailPopupModifierFontColor,
  ] = useState("");
  const [
    menuDetailPopupModifierPriceFontSize,
    setMenuDetailPopupModifierPriceFontSize,
  ] = useState("");
  const [
    menuDetailPopupAddToCartBtnBgColor,
    setMenuDetailPopupAddToCartBtnBgColor,
  ] = useState("");
  const [
    menuDetailPopupAddToCartBtnFontColor,
    setMenuDetailPopupAddToCartBtnFontColor,
  ] = useState("");

  const [cartPageBgColor, setCartPageBgColor] = useState("");
  const [cartPageCardHeaderFontSize, setCartPageCardHeaderFontSize] =
    useState("");
  const [cartPageCardItemNameFontSize, setCartPageCardItemNameFontSize] =
    useState("");
  const [cartPageCardItemPriceFontSize, setCartPageCardItemPriceFontSize] =
    useState("");

  const [checkoutPageBgColor, setCheckoutPageBgColor] = useState("");
  const [checkoutPagePaymentBtnBgColor, setCheckoutPagePaymentBtnBgColor] =
    useState("");
  const [checkoutPagePaymentBtnFontColor, setCheckoutPagePaymentBtnFontColor] =
    useState("");
  const [checkoutPageCardsBgColor, setCheckoutPageCardsBgColor] = useState("");
  const [checkoutPageFontColor, setCheckoutPageFontColor] = useState("");
  const [
    checkoutPageBillingPriceFontSize,
    setCheckoutPageBillingPriceFontSize,
  ] = useState("");
  const [
    checkoutPageBillingTotalPriceFontSize,
    setCheckoutPageBillingTotalPriceFontSize,
  ] = useState("");

  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const [imageLoading, setImageLoading] = useState(false);
  const [imageType, setImageType] = useState("");

  const [tableOrder, setTableOrder] = useState(false);
  const [onlineOrder, setOnlineOrder] = useState(false);
  const [tablePayment, setTablePayment] = useState(false);
  const [wifiAvailable, setWifiAvailable] = useState(false);

  const [socialMediaList, setSocialMediaList] = useState([]);
  const [appType, setAppType] = useState(APP_TYPE_FOODO);

  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let data = restaurantDetail;
    let fontNameFile = null;
    if (menuItemFontName || menuItemFontFile) {
      fontNameFile = JSON.stringify({
        name: menuItemFontName,
        url: menuItemFontFile?.image ? menuItemFontFile?.image : "",
      });
    }

    const themeColors = data?.themeColors;

    const {
      menuListingBgColor = null,
      menuListingFontColor = null,
      menuListingCategoryFontColor = null,
      menuListingCategoryBgColor = null,
      menuListingSelectedCategoryFontColor = null,
      menuListingSelectedCategoryBgColor = null,
      menuPopupItemFontColor = null,
      menuPopupItemPriceFontSize = null,
      menuPopupItemBgColor = null,
      menuPopupModifierBgColor = null,
      menuPopupModifierFontColor = null,
      menuPopupModifierPriceFontSize = null,
      menuPopupAddToCartBtnBgColor = null,
      menuPopupAddToCartBtnFontColor = null,
      checkoutBgColor = null,
      checkoutPaymentBtnBgColor = null,
      checkoutPaymentBtnFontColor = null,
      checkoutCardsBgColor = null,
      checkoutFontColor = null,
      checkoutBillingPriceFontSize = null,
      checkoutBillingTotalPriceFontSize = null,
      cartBgColor = null,
      cartCardHeaderFontSize = null,
      cartCardItemNameFontSize = null,
      cartCardItemPriceFontSize = null,
    } = themeColors || {};

    const themeColor = {
      headerBg: themeColors?.headerBg ?? null,
      categoryUnderlineColor: themeColors?.categoryUnderlineColor ?? null,
      itemPriceFontColor: themeColors?.itemPriceFontColor ?? null,
      itemPriceBgColor: themeColors?.itemPriceBgColor ?? null,
      arViewBgColor: themeColors?.arViewBgColor ?? null,
      nextIcon: themeColors?.nextIcon ?? null,
      prevIcon: themeColors?.prevIcon ?? null,
      menuSectionBg: themeColors?.menuSectionBg ?? null,
      menuListingBgColor,
      menuListingFontColor,
      menuListingCategoryFontColor,
      menuListingCategoryBgColor,
      menuListingSelectedCategoryFontColor,
      menuListingSelectedCategoryBgColor,
      menuPopupItemFontColor,
      menuPopupItemPriceFontSize,
      menuPopupItemBgColor,
      menuPopupModifierBgColor,
      menuPopupModifierFontColor,
      menuPopupModifierPriceFontSize,
      menuPopupAddToCartBtnBgColor,
      menuPopupAddToCartBtnFontColor,
      checkoutBgColor,
      checkoutPaymentBtnBgColor,
      checkoutPaymentBtnFontColor,
      checkoutCardsBgColor,
      checkoutFontColor,
      checkoutBillingPriceFontSize,
      checkoutBillingTotalPriceFontSize,
      cartBgColor,
      cartCardHeaderFontSize,
      cartCardItemNameFontSize,
      cartCardItemPriceFontSize,
    };

    let media_list = [];
    socialMediaList.map((x, i) => {
      let media = {
        name: x?.name,
        action: x?.action,
        icon: x?.icon?.image ? x?.icon?.image : x?.icon ? x?.icon : null,
        data: x?.data,
      };
      if (x?._id) {
        media = {
          ...media,
          _id: x?._id,
        };
      }
      media_list.push(media);
    });

    let social_media_list = [];
    data?.socialMedias?.map((x, i) => {
      let media = {
        name: x?.name,
        action: x?.action,
        icon: x?.icon,
        data: x?.data,
      };
      if (x?._id) {
        media = {
          ...media,
          _id: x?._id,
        };
      }
      social_media_list.push(media);
    });

    let data1 = {
      headerBg: headerBg?.image ? headerBg.image : null,
      arModelBg: arModelBg?.image ? arModelBg.image : null,
      printLogo: printLogo?.image ?? "",
      bottomMenuIcon: bottomMenuIcon?.image ? bottomMenuIcon.image : null,
      menuItemFontBg: menuItemFontBg ? menuItemFontBg : null,
      menuItemFontColor: menuItemFontColor ? menuItemFontColor : null,
      menuItemNameFont: fontNameFile,
      userFormDataBg: userFormDataBg ? userFormDataBg : null,
      userFormDataLabelColor: userFormDataLabelColor
        ? userFormDataLabelColor
        : null,
      modalBgColor: modalBgColor ? modalBgColor : null,
      modalFontColor: modalFontColor ? modalFontColor : null,
      userFormDataFontColor: userFormDataFontColor
        ? userFormDataFontColor
        : null,
      buttonBg: buttonBg ? buttonBg : null,
      buttonTextBg: buttonTextBg ? buttonTextBg : null,
      tableOrder: tableOrder,
      onlineOrder: onlineOrder,
      tablePayment: tablePayment,
      wifiAvailable: wifiAvailable,
      socialMedias: media_list,
      appType: appType,
      themeColors: {
        headerBg: menuHeaderBg?.image ? menuHeaderBg?.image : null,
        categoryUnderlineColor: categoryUnderlineColor
          ? categoryUnderlineColor
          : null,
        itemPriceFontColor: itemPriceFontColor ? itemPriceFontColor : null,
        itemPriceBgColor: itemPriceBgColor ? itemPriceBgColor : null,
        arViewBgColor: arViewBgColor ? arViewBgColor : null,
        nextIcon: nextIconVal?.image ?? null,
        prevIcon: prevIconVal?.image ?? null,
        menuSectionBg: menuSectionBgVal?.image ?? null,
        menuListingBgColor: menuListBgColor ? menuListBgColor : null,
        menuListingFontColor: menuListFontColor ? menuListFontColor : null,
        menuListingCategoryFontColor: menuListCatFontColor
          ? menuListCatFontColor
          : null,
        menuListingCategoryBgColor: menuListCatBgColor
          ? menuListCatBgColor
          : null,
        menuListingSelectedCategoryFontColor: menuListSelectedCatFontColor
          ? menuListSelectedCatFontColor
          : null,
        menuListingSelectedCategoryBgColor: menuListSelectedCatBgColor
          ? menuListSelectedCatBgColor
          : null,
        menuPopupItemFontColor: menuDetailPopupItemFontColor
          ? menuDetailPopupItemFontColor
          : null,
        menuPopupItemPriceFontSize: menuDetailPopupItemPriceFontSize
          ? menuDetailPopupItemPriceFontSize
          : null,
        menuPopupItemBgColor: menuDetailPopupItemBgColor
          ? menuDetailPopupItemBgColor
          : null,
        menuPopupModifierBgColor: menuDetailPopupModifierBgColor
          ? menuDetailPopupModifierBgColor
          : null,
        menuPopupModifierFontColor: menuDetailPopupModifierFontColor
          ? menuDetailPopupModifierFontColor
          : null,
        menuPopupModifierPriceFontSize: menuDetailPopupModifierPriceFontSize
          ? menuDetailPopupModifierPriceFontSize
          : null,
        menuPopupAddToCartBtnBgColor: menuDetailPopupAddToCartBtnBgColor
          ? menuDetailPopupAddToCartBtnBgColor
          : null,
        menuPopupAddToCartBtnFontColor: menuDetailPopupAddToCartBtnFontColor
          ? menuDetailPopupAddToCartBtnFontColor
          : null,
        checkoutBgColor: checkoutPageBgColor ? checkoutPageBgColor : null,
        checkoutPaymentBtnBgColor: checkoutPagePaymentBtnBgColor
          ? checkoutPagePaymentBtnBgColor
          : null,
        checkoutPaymentBtnFontColor: checkoutPagePaymentBtnFontColor
          ? checkoutPagePaymentBtnFontColor
          : null,
        checkoutCardsBgColor: checkoutPageCardsBgColor
          ? checkoutPageCardsBgColor
          : null,
        checkoutFontColor: checkoutPageFontColor ? checkoutPageFontColor : null,
        checkoutBillingPriceFontSize: checkoutPageBillingPriceFontSize
          ? checkoutPageBillingPriceFontSize
          : null,
        checkoutBillingTotalPriceFontSize: checkoutPageBillingTotalPriceFontSize
          ? checkoutPageBillingTotalPriceFontSize
          : null,
        cartBgColor: cartPageBgColor ? cartPageBgColor : null,
        cartCardHeaderFontSize: cartPageCardHeaderFontSize
          ? cartPageCardHeaderFontSize
          : null,
        cartCardItemNameFontSize: cartPageCardItemNameFontSize
          ? cartPageCardItemNameFontSize
          : null,
        cartCardItemPriceFontSize: cartPageCardItemPriceFontSize
          ? cartPageCardItemPriceFontSize
          : null,
      },
    };

    if (
      data?.headerBg !== data1.headerBg ||
      data?.arModelBg !== data1.arModelBg ||
      data?.printLogo !== data1.printLogo ||
      data?.bottomMenuIcon !== data1.bottomMenuIcon ||
      data?.menuItemFontBg !== data1.menuItemFontBg ||
      data?.menuItemFontColor !== data1.menuItemFontColor ||
      data?.menuItemNameFont !== data1.menuItemNameFont ||
      data?.userFormDataBg !== data1.userFormDataBg ||
      data?.userFormDataLabelColor !== data1.userFormDataLabelColor ||
      data?.modalBgColor !== data1.modalBgColor ||
      data?.modalFontColor !== data1.modalFontColor ||
      data?.userFormDataFontColor !== data1.userFormDataFontColor ||
      data?.buttonBg !== data1.buttonBg ||
      data?.buttonTextBg !== data1.buttonTextBg ||
      data?.tableOrder !== data1.tableOrder ||
      data?.onlineOrder !== data1.onlineOrder ||
      data?.tablePayment !== data1.tablePayment ||
      data?.wifiAvailable !== data1.wifiAvailable ||
      data?.appType !== data1.appType ||
      JSON.stringify(themeColor) !== JSON.stringify(data1.themeColors) ||
      JSON.stringify(social_media_list) !== JSON.stringify(data1.socialMedias)
    ) {
      setSaveBtnDisabled(false);
    } else {
      setSaveBtnDisabled(true);
    }
  }, [
    headerBg,
    arModelBg,
    printLogo,
    bottomMenuIcon,
    nextIconVal,
    prevIconVal,
    menuSectionBgVal,
    menuItemFontColor,
    menuItemFontBg,
    menuItemFontFile,
    menuItemFontName,
    userFormDataBg,
    userFormDataLabelColor,
    modalBgColor,
    modalFontColor,
    userFormDataFontColor,
    buttonBg,
    buttonTextBg,
    menuHeaderBg,
    arViewBgColor,
    itemPriceBgColor,
    itemPriceFontColor,
    categoryUnderlineColor,
    tableOrder,
    onlineOrder,
    tablePayment,
    wifiAvailable,
    socialMediaList,
    appType,
    menuListBgColor,
    menuListFontColor,
    menuListCatFontColor,
    menuListCatBgColor,
    menuListSelectedCatFontColor,
    menuListSelectedCatBgColor,
    menuDetailPopupItemFontColor,
    menuDetailPopupItemPriceFontSize,
    menuDetailPopupItemBgColor,
    menuDetailPopupModifierBgColor,
    menuDetailPopupModifierFontColor,
    menuDetailPopupModifierPriceFontSize,
    menuDetailPopupAddToCartBtnBgColor,
    menuDetailPopupAddToCartBtnFontColor,
    checkoutPageBgColor,
    checkoutPagePaymentBtnBgColor,
    checkoutPagePaymentBtnFontColor,
    checkoutPageCardsBgColor,
    checkoutPageFontColor,
    checkoutPageBillingPriceFontSize,
    checkoutPageBillingTotalPriceFontSize,
    cartPageBgColor,
    cartPageCardHeaderFontSize,
    cartPageCardItemNameFontSize,
    cartPageCardItemPriceFontSize,
    restaurantDetail,
  ]);

  useEffect(() => {
    if (!RESTLIST.includes(restaurantId)) {
      setMenuItemFontBg("#2f5831");
      setMenuItemFontColor("#FFFFFF");
      setModalFontColor("#FFFFFF");
      setUserFormDataBg("#CA7128");
      setUserFormDataLabelColor("#FFFFFF");
      setUserFormDataFontColor("#FFFFFF");
      setButtonBg("#FFFFFF");
      setButtonTextBg("#D87025");
      setCategoryUnderlineColor("#D87025");
      setItemPriceFontColor("#FFFFFF");
      setItemPriceBgColor("#0d5f13");
      setArViewBgColor("#D87025");
    }

    getRestaurantData();
  }, []);

  const getRestaurantData = () => {
    setLoading(true);
    Api.getRestaurantData(restaurantId).then((response) => {
      if (response.success) {
        let resData = response.data;

        if (resData?.menuItemFontBg) {
          setMenuItemFontBg(resData.menuItemFontBg);
        }

        if (resData?.menuItemFontColor) {
          setMenuItemFontColor(resData.menuItemFontColor);
        }

        if (resData?.menuItemNameFont) {
          let fileNameFile = JSON.parse(resData.menuItemNameFont);
          setMenuItemFontName(fileNameFile?.name ? fileNameFile?.name : "");
          if (fileNameFile?.url) {
            setMenuItemFontFile(
              getImgDetail(fileNameFile.url, "menuItemFontFile")
            );
          }
        }

        let header_bg = resData?.headerBg;
        if (header_bg) {
          setHeaderBg(getImgDetail(header_bg, "headerBg"));
        }

        let ar_model_bg = resData?.arModelBg;
        if (ar_model_bg) {
          setArModelBg(getImgDetail(ar_model_bg, "arModelBg"));
        }

        const printLogoImg = resData?.printLogo;
        if (printLogoImg) {
          setPrintLogo(getImgDetail(printLogoImg, "printLogo"));
        }

        let bottom_menu_icon = resData?.bottomMenuIcon;
        if (bottom_menu_icon) {
          setBottomMenuIcon(getImgDetail(bottom_menu_icon, "bottomMenuIcon"));
        }

        if (resData?.userFormDataBg) {
          setUserFormDataBg(resData.userFormDataBg);
        }

        if (resData?.userFormDataLabelColor) {
          setUserFormDataLabelColor(resData.userFormDataLabelColor);
        }

        if (resData?.modalBgColor) {
          setModalBgColor(resData.modalBgColor);
        }

        if (resData?.modalFontColor) {
          setModalFontColor(resData.modalFontColor);
        }

        if (resData?.userFormDataFontColor) {
          setUserFormDataFontColor(resData.userFormDataFontColor);
        }

        if (resData?.buttonBg) {
          setButtonBg(resData.buttonBg);
        }

        if (resData?.buttonTextBg) {
          setButtonTextBg(resData.buttonTextBg);
        }

        if (resData?.themeColors) {
          let themeColor = resData?.themeColors;

          const {
            menuListingBgColor = null,
            menuListingFontColor = null,
            menuListingCategoryFontColor = null,
            menuListingCategoryBgColor = null,
            menuListingSelectedCategoryFontColor = null,
            menuListingSelectedCategoryBgColor = null,
            menuPopupItemFontColor = null,
            menuPopupItemPriceFontSize = null,
            menuPopupItemBgColor = null,
            menuPopupModifierBgColor = null,
            menuPopupModifierFontColor = null,
            menuPopupModifierPriceFontSize = null,
            menuPopupAddToCartBtnBgColor = null,
            menuPopupAddToCartBtnFontColor = null,
            checkoutBgColor = null,
            checkoutPaymentBtnBgColor = null,
            checkoutPaymentBtnFontColor = null,
            checkoutCardsBgColor = null,
            checkoutFontColor = null,
            checkoutBillingPriceFontSize = null,
            checkoutBillingTotalPriceFontSize = null,
            cartBgColor = null,
            cartCardHeaderFontSize = null,
            cartCardItemNameFontSize = null,
            cartCardItemPriceFontSize = null,
          } = themeColor || {};

          if (themeColor?.nextIcon) {
            setNextIconVal(getImgDetail(themeColor.nextIcon, "nextIcon"));
          }

          if (themeColor?.prevIcon) {
            setPrevIconVal(getImgDetail(themeColor.prevIcon, "prevIcon"));
          }

          if (themeColor?.menuSectionBg) {
            setMenuSectionBgVal(
              getImgDetail(themeColor.menuSectionBg, "menuSectionBg")
            );
          }

          if (themeColor?.headerBg) {
            setMenuHeaderBg(getImgDetail(themeColor.headerBg, "menuHeaderBg"));
          }

          if (themeColor?.categoryUnderlineColor) {
            setCategoryUnderlineColor(themeColor.categoryUnderlineColor);
          }
          if (themeColor?.itemPriceFontColor) {
            setItemPriceFontColor(themeColor.itemPriceFontColor);
          }
          if (themeColor?.itemPriceBgColor) {
            setItemPriceBgColor(themeColor.itemPriceBgColor);
          }
          if (themeColor?.arViewBgColor) {
            setArViewBgColor(themeColor.arViewBgColor);
          }

          if (menuListingBgColor) {
            setMenuListBgColor(menuListingBgColor);
          }

          if (menuListingFontColor) {
            setMenuListFontColor(menuListingFontColor);
          }

          if (menuListingCategoryFontColor) {
            setMenuListCatFontColor(menuListingCategoryFontColor);
          }

          if (menuListingCategoryBgColor) {
            setMenuListCatBgColor(menuListingCategoryBgColor);
          }

          if (menuListingSelectedCategoryFontColor) {
            setMenuListSelectedCatFontColor(
              menuListingSelectedCategoryFontColor
            );
          }

          if (menuListingSelectedCategoryBgColor) {
            setMenuListSelectedCatBgColor(menuListingSelectedCategoryBgColor);
          }

          if (menuPopupItemFontColor) {
            setMenuDetailPopupItemFontColor(menuPopupItemFontColor);
          }

          if (menuPopupItemPriceFontSize) {
            setMenuDetailPopupItemPriceFontSize(menuPopupItemPriceFontSize);
          }

          if (menuPopupItemBgColor) {
            setMenuDetailPopupItemBgColor(menuPopupItemBgColor);
          }

          if (menuPopupModifierBgColor) {
            setMenuDetailPopupModifierBgColor(menuPopupModifierBgColor);
          }

          if (menuPopupModifierFontColor) {
            setMenuDetailPopupModifierFontColor(menuPopupModifierFontColor);
          }

          if (menuPopupModifierPriceFontSize) {
            setMenuDetailPopupModifierPriceFontSize(
              menuPopupModifierPriceFontSize
            );
          }

          if (menuPopupAddToCartBtnBgColor) {
            setMenuDetailPopupAddToCartBtnBgColor(menuPopupAddToCartBtnBgColor);
          }

          if (menuPopupAddToCartBtnFontColor) {
            setMenuDetailPopupAddToCartBtnFontColor(
              menuPopupAddToCartBtnFontColor
            );
          }

          if (checkoutBgColor) {
            setCheckoutPageBgColor(checkoutBgColor);
          }

          if (checkoutPaymentBtnBgColor) {
            setCheckoutPagePaymentBtnBgColor(checkoutPaymentBtnBgColor);
          }

          if (checkoutPaymentBtnFontColor) {
            setCheckoutPagePaymentBtnFontColor(checkoutPaymentBtnFontColor);
          }

          if (checkoutCardsBgColor) {
            setCheckoutPageCardsBgColor(checkoutCardsBgColor);
          }

          if (checkoutFontColor) {
            setCheckoutPageFontColor(checkoutFontColor);
          }

          if (checkoutBillingPriceFontSize) {
            setCheckoutPageBillingPriceFontSize(checkoutBillingPriceFontSize);
          }

          if (checkoutBillingTotalPriceFontSize) {
            setCheckoutPageBillingTotalPriceFontSize(
              checkoutBillingTotalPriceFontSize
            );
          }

          if (cartBgColor) {
            setCartPageBgColor(cartBgColor);
          }

          if (cartCardHeaderFontSize) {
            setCartPageCardHeaderFontSize(cartCardHeaderFontSize);
          }

          if (cartCardItemNameFontSize) {
            setCartPageCardItemNameFontSize(cartCardItemNameFontSize);
          }

          if (cartCardItemPriceFontSize) {
            setCartPageCardItemPriceFontSize(cartCardItemPriceFontSize);
          }
        }

        setTableOrder(resData?.tableOrder);
        setOnlineOrder(resData?.onlineOrder);
        setTablePayment(resData?.tablePayment);
        setWifiAvailable(resData?.wifiAvailable);

        if (resData?.socialMedias?.length > 0) {
          setSocialMediaList(resData?.socialMedias);
        }

        if (resData?.appType) {
          setAppType(resData?.appType);
        }

        setRestaurantDetail(resData);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const getImgDetail = (file, type) => {
    let index1 = file.lastIndexOf("/") + 1;
    let filename = file.substr(index1);
    return {
      id: new Date() + type,
      name: filename,
      displayName: filename,
      size: 0,
      image: file,
      upload: true,
    };
  };

  const onAddEntry = () => {
    setSocialMediaList([
      ...socialMediaList,
      {
        name: "",
        action: "",
        icon: "",
        data: {
          url: "",
          iconHeight: "",
          iconWidth: "",
        },
      },
    ]);
  };

  const onUpdateSocialMedia = (field, value, index) => {
    let list = [...socialMediaList];
    let findIndex = list.findIndex((x, i) => i == index);
    if (findIndex >= 0) {
      if (["url", "iconHeight", "iconWidth"].includes(field)) {
        let data = {
          ...list[findIndex]?.data,
          [field]: value,
        };
        list[findIndex] = {
          ...list[findIndex],
          data: data,
        };
      } else {
        list[findIndex] = {
          ...list[findIndex],
          [field]: value,
        };
      }
      setSocialMediaList(list);
    }
  };

  const onDeleteSocialMedia = (index) => {
    setSocialMediaList(socialMediaList.filter((x, i) => i != index));
  };

  const onCheckColorFormatErr = (errParam, value) => {
    if (value && !colorValidation(value)) {
      return {
        [errParam]: "Invalid Color Code",
      };
    }
    return {};
  };

  const onSave = () => {
    let err = {};
    if (menuItemFontColor) {
      if (colorValidation(menuItemFontColor)) {
      } else {
        err = {
          ...err,
          menuItemFontColor: "Invalid Color Code",
        };
      }
    }

    if (menuItemFontBg) {
      if (colorValidation(menuItemFontBg)) {
      } else {
        err = {
          ...err,
          menuItemFontBg: "Invalid Color Code",
        };
      }
    }

    if (userFormDataBg) {
      if (colorValidation(userFormDataBg)) {
      } else {
        err = {
          ...err,
          userFormDataBg: "Invalid Color Code",
        };
      }
    }

    if (userFormDataLabelColor) {
      if (colorValidation(userFormDataLabelColor)) {
      } else {
        err = {
          ...err,
          userFormDataLabelColor: "Invalid Color Code",
        };
      }
    }

    if (modalBgColor) {
      if (colorValidation(modalBgColor)) {
      } else {
        err = {
          ...err,
          modalBgColor: "Invalid Color Code",
        };
      }
    }

    if (modalFontColor) {
      if (colorValidation(modalFontColor)) {
      } else {
        err = {
          ...err,
          modalFontColor: "Invalid Color Code",
        };
      }
    }

    if (userFormDataFontColor) {
      if (colorValidation(userFormDataFontColor)) {
      } else {
        err = {
          ...err,
          userFormDataFontColor: "Invalid Color Code",
        };
      }
    }

    if (buttonBg) {
      if (colorValidation(buttonBg)) {
      } else {
        err = {
          ...err,
          buttonBg: "Invalid Color Code",
        };
      }
    }

    if (buttonTextBg) {
      if (colorValidation(buttonTextBg)) {
      } else {
        err = {
          ...err,
          buttonTextBg: "Invalid Color Code",
        };
      }
    }

    if (categoryUnderlineColor) {
      if (colorValidation(categoryUnderlineColor)) {
      } else {
        err = {
          ...err,
          categoryUnderlineColor: "Invalid Color Code",
        };
      }
    }

    if (itemPriceFontColor) {
      if (colorValidation(itemPriceFontColor)) {
      } else {
        err = {
          ...err,
          itemPriceFontColor: "Invalid Color Code",
        };
      }
    }

    if (itemPriceBgColor) {
      if (colorValidation(itemPriceBgColor)) {
      } else {
        err = {
          ...err,
          itemPriceBgColor: "Invalid Color Code",
        };
      }
    }

    if (arViewBgColor) {
      if (colorValidation(arViewBgColor)) {
      } else {
        err = {
          ...err,
          arViewBgColor: "Invalid Color Code",
        };
      }
    }

    err = {
      ...err,
      ...onCheckColorFormatErr("menuListBgColor", menuListBgColor),
      ...onCheckColorFormatErr("menuListFontColor", menuListFontColor),
      ...onCheckColorFormatErr("menuListCatBgColor", menuListCatBgColor),
      ...onCheckColorFormatErr("menuListCatFontColor", menuListCatFontColor),
      ...onCheckColorFormatErr(
        "menuListSelectedCatBgColor",
        menuListSelectedCatBgColor
      ),
      ...onCheckColorFormatErr(
        "menuListSelectedCatFontColor",
        menuListSelectedCatFontColor
      ),
      ...onCheckColorFormatErr(
        "menuDetailPopupItemFontColor",
        menuDetailPopupItemFontColor
      ),
      ...onCheckColorFormatErr(
        "menuDetailPopupItemBgColor",
        menuDetailPopupItemBgColor
      ),
      ...onCheckColorFormatErr(
        "menuDetailPopupModifierBgColor",
        menuDetailPopupModifierBgColor
      ),
      ...onCheckColorFormatErr(
        "menuDetailPopupModifierFontColor",
        menuDetailPopupModifierFontColor
      ),
      ...onCheckColorFormatErr(
        "menuDetailPopupAddToCartBtnBgColor",
        menuDetailPopupAddToCartBtnBgColor
      ),
      ...onCheckColorFormatErr(
        "menuDetailPopupAddToCartBtnFontColor",
        menuDetailPopupAddToCartBtnFontColor
      ),
      ...onCheckColorFormatErr("cartPageBgColor", cartPageBgColor),
      ...onCheckColorFormatErr("checkoutPageBgColor", checkoutPageBgColor),
      ...onCheckColorFormatErr(
        "checkoutPagePaymentBtnBgColor",
        checkoutPagePaymentBtnBgColor
      ),
      ...onCheckColorFormatErr(
        "checkoutPagePaymentBtnFontColor",
        checkoutPagePaymentBtnFontColor
      ),
      ...onCheckColorFormatErr(
        "checkoutPageCardsBgColor",
        checkoutPageCardsBgColor
      ),
      ...onCheckColorFormatErr("checkoutPageFontColor", checkoutPageFontColor),
    };

    let media_err = false;
    let mediaList = [];
    let list = [...socialMediaList];
    list.map((x, i) => {
      let err = null;
      if (x?.icon?.image || x?.icon) {
      } else {
        media_err = true;
        err = {
          ...err,
          icon: "Icon Required",
        };
      }

      if (x?.data?.url) {
      } else {
        media_err = true;
        err = {
          ...err,
          url: "Url Required",
        };
      }

      list[i] = { ...x, error: err };

      if (err) {
      } else {
        let icon_height = x?.data?.iconHeight?.replace(/[^0-9.]/g, "");
        let icon_width = x?.data?.iconWidth?.replace(/[^0-9.]/g, "");

        let data_data = {
          url: x?.data?.url,
        };

        if (x?.data?.iconHeight?.replace(/[^0-9.]/g, "")) {
          data_data = {
            ...data_data,
            iconHeight: icon_height,
          };
        }

        if (x?.data?.iconWidth?.replace(/[^0-9.]/g, "")) {
          data_data = {
            ...data_data,
            iconWidth: icon_width,
          };
        }

        let data = {
          name: x?.name,
          action: x?.action,
          icon: x?.icon?.image ? x?.icon?.image : x?.icon ? x?.icon : null,
          data: data_data,
        };
        if (x?._id) {
          data = { ...data, _id: x?._id };
        }
        mediaList.push(data);
      }
    });

    if (Object.getOwnPropertyNames(err).length > 0) {
      setError(err);
    }

    if (media_err) {
      setSocialMediaList(list);
    }

    if (Object.getOwnPropertyNames(err).length > 0 || media_err) {
      return;
    }

    let fontNameFile = null;
    if (menuItemFontName || menuItemFontFile) {
      fontNameFile = JSON.stringify({
        name: menuItemFontName,
        url: menuItemFontFile?.image ? menuItemFontFile?.image : "",
      });
    }

    let data = {
      headerBg: headerBg?.image ? headerBg.image : null,
      arModelBg: arModelBg?.image ? arModelBg.image : null,
      printLogo: printLogo?.image ?? "",
      bottomMenuIcon: bottomMenuIcon?.image ? bottomMenuIcon.image : null,
      menuItemFontBg: menuItemFontBg ? menuItemFontBg : null,
      menuItemFontColor: menuItemFontColor ? menuItemFontColor : null,
      menuItemNameFont: fontNameFile,
      userFormDataBg: userFormDataBg ? userFormDataBg : null,
      userFormDataLabelColor: userFormDataLabelColor
        ? userFormDataLabelColor
        : null,
      modalBgColor: modalBgColor ? modalBgColor : null,
      modalFontColor: modalFontColor ? modalFontColor : null,
      userFormDataFontColor: userFormDataFontColor
        ? userFormDataFontColor
        : null,
      buttonBg: buttonBg ? buttonBg : null,
      buttonTextBg: buttonTextBg ? buttonTextBg : null,
      themeColors: {
        headerBg: menuHeaderBg?.image ? menuHeaderBg?.image : null,
        categoryUnderlineColor: categoryUnderlineColor
          ? categoryUnderlineColor
          : null,
        itemPriceFontColor: itemPriceFontColor ? itemPriceFontColor : null,
        itemPriceBgColor: itemPriceBgColor ? itemPriceBgColor : null,
        arViewBgColor: arViewBgColor ? arViewBgColor : null,
        nextIcon: nextIconVal?.image ?? null,
        prevIcon: prevIconVal?.image ?? null,
        menuSectionBg: menuSectionBgVal?.image ?? null,
        menuListingBgColor: menuListBgColor ? menuListBgColor : null,
        menuListingFontColor: menuListFontColor ? menuListFontColor : null,
        menuListingCategoryFontColor: menuListCatFontColor
          ? menuListCatFontColor
          : null,
        menuListingCategoryBgColor: menuListCatBgColor
          ? menuListCatBgColor
          : null,
        menuListingSelectedCategoryFontColor: menuListSelectedCatFontColor
          ? menuListSelectedCatFontColor
          : null,
        menuListingSelectedCategoryBgColor: menuListSelectedCatBgColor
          ? menuListSelectedCatBgColor
          : null,
        menuPopupItemFontColor: menuDetailPopupItemFontColor
          ? menuDetailPopupItemFontColor
          : null,
        menuPopupItemPriceFontSize: menuDetailPopupItemPriceFontSize
          ? menuDetailPopupItemPriceFontSize
          : null,
        menuPopupItemBgColor: menuDetailPopupItemBgColor
          ? menuDetailPopupItemBgColor
          : null,
        menuPopupModifierBgColor: menuDetailPopupModifierBgColor
          ? menuDetailPopupModifierBgColor
          : null,
        menuPopupModifierFontColor: menuDetailPopupModifierFontColor
          ? menuDetailPopupModifierFontColor
          : null,
        menuPopupModifierPriceFontSize: menuDetailPopupModifierPriceFontSize
          ? menuDetailPopupModifierPriceFontSize
          : null,
        menuPopupAddToCartBtnBgColor: menuDetailPopupAddToCartBtnBgColor
          ? menuDetailPopupAddToCartBtnBgColor
          : null,
        menuPopupAddToCartBtnFontColor: menuDetailPopupAddToCartBtnFontColor
          ? menuDetailPopupAddToCartBtnFontColor
          : null,
        checkoutBgColor: checkoutPageBgColor ? checkoutPageBgColor : null,
        checkoutPaymentBtnBgColor: checkoutPagePaymentBtnBgColor
          ? checkoutPagePaymentBtnBgColor
          : null,
        checkoutPaymentBtnFontColor: checkoutPagePaymentBtnFontColor
          ? checkoutPagePaymentBtnFontColor
          : null,
        checkoutCardsBgColor: checkoutPageCardsBgColor
          ? checkoutPageCardsBgColor
          : null,
        checkoutFontColor: checkoutPageFontColor ? checkoutPageFontColor : null,
        checkoutBillingPriceFontSize: checkoutPageBillingPriceFontSize
          ? checkoutPageBillingPriceFontSize
          : null,
        checkoutBillingTotalPriceFontSize: checkoutPageBillingTotalPriceFontSize
          ? checkoutPageBillingTotalPriceFontSize
          : null,
        cartBgColor: cartPageBgColor ? cartPageBgColor : null,
        cartCardHeaderFontSize: cartPageCardHeaderFontSize
          ? cartPageCardHeaderFontSize
          : null,
        cartCardItemNameFontSize: cartPageCardItemNameFontSize
          ? cartPageCardItemNameFontSize
          : null,
        cartCardItemPriceFontSize: cartPageCardItemPriceFontSize
          ? cartPageCardItemPriceFontSize
          : null,
      },
      tableOrder: tableOrder,
      onlineOrder: onlineOrder,
      tablePayment: tablePayment,
      wifiAvailable: wifiAvailable,
      socialMedias: mediaList,
      appType: appType,
    };

    let datas = JSON.stringify(data);

    setLoading(true);
    Api.updateRestaurant(datas, restaurantId).then((response) => {
      if (response.success) {
        setRestaurantDetail({
          ...restaurantDetail,
          ...data,
        });
        setSaveBtnDisabled(true);
        setMsgAlert({ open: true, message: response.msg, msgType: "success" });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const uploadLogoFile = (data, file, type) => {
    setImageType(type);
    fileUploadApi(file, data, type);
  };

  const fileUploadApi = (file, data, type) => {
    setImageLoading(true);

    let formData = new FormData();
    formData.append("image", file);

    Api.uploadImageFile(formData).then((response) => {
      if (response.success) {
        data["name"] = response.data.fileId;
        data["image"] = response.data.location;
        data["upload"] = true;
        if (type == "headerBg") {
          setHeaderBg(data);
        } else if (type == "arModelBg") {
          setArModelBg(data);
        } else if (type === "printLogo") {
          setPrintLogo(data);
        } else if (type == "bottomMenuIcon") {
          setBottomMenuIcon(data);
        } else if (type == "menuItemFontFile") {
          setMenuItemFontFile(data);
        } else if (type == "menuHeaderBg") {
          setMenuHeaderBg(data);
        } else if (type === "nextIcon") {
          setNextIconVal(data);
        } else if (type === "prevIcon") {
          setPrevIconVal(data);
        } else if (type === "menuSectionBg") {
          setMenuSectionBgVal(data);
        } else if (type.includes("socialMedia")) {
          let index = type.replace("socialMedia", "");
          if (index) {
            onUpdateSocialMedia("icon", data, Number(index));
          }
        }
        setImageLoading(false);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const colorValidation = (val) => {
    return /^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(val);
  };

  const handlerOnRemoveImg = (type) => {
    if (type === "printLogo") {
      setPrintLogo("");
    } else if (type === "headerBg") {
      setHeaderBg("");
    } else if (type === "menuHeaderBg") {
      setMenuHeaderBg("");
    } else if (type === "arModelBg") {
      setArModelBg("");
    } else if (type === "bottomMenuIcon") {
      setBottomMenuIcon("");
    } else if (type === "menuSectionBg") {
      setMenuSectionBgVal("");
    } else if (type === "prevIcon") {
      setPrevIconVal("");
    } else if (type === "nextIcon") {
      setNextIconVal("");
    }
  };

  const ColorView = ({ color }) => {
    return (
      <Box
        sx={{
          width: 18,
          height: 18,
          border: "0.5px solid #AAA",
          backgroundColor: color,
        }}
      />
    );
  };

  const handlerOnColorChange = (field, val) => {
    if (colorValidation(val) || val === "") {
      setError({ ...error, [field]: "" });
    } else {
      setError({
        ...error,
        [field]: "Invalid Color Code",
      });
    }
    if (field === "menuListBgColor") {
      setMenuListBgColor(val);
    } else if (field === "menuListFontColor") {
      setMenuListFontColor(val);
    } else if (field === "menuListCatFontColor") {
      setMenuListCatFontColor(val);
    } else if (field === "menuListCatBgColor") {
      setMenuListCatBgColor(val);
    } else if (field === "menuListSelectedCatFontColor") {
      setMenuListSelectedCatFontColor(val);
    } else if (field === "menuListSelectedCatBgColor") {
      setMenuListSelectedCatBgColor(val);
    } else if (field === "menuDetailPopupItemFontColor") {
      setMenuDetailPopupItemFontColor(val);
    } else if (field === "menuDetailPopupItemBgColor") {
      setMenuDetailPopupItemBgColor(val);
    } else if (field === "menuDetailPopupModifierBgColor") {
      setMenuDetailPopupModifierBgColor(val);
    } else if (field === "menuDetailPopupModifierFontColor") {
      setMenuDetailPopupModifierFontColor(val);
    } else if (field === "menuDetailPopupAddToCartBtnBgColor") {
      setMenuDetailPopupAddToCartBtnBgColor(val);
    } else if (field === "menuDetailPopupAddToCartBtnFontColor") {
      setMenuDetailPopupAddToCartBtnFontColor(val);
    } else if (field === "cartPageBgColor") {
      setCartPageBgColor(val);
    } else if (field === "checkoutPageBgColor") {
      setCheckoutPageBgColor(val);
    } else if (field === "checkoutPageCardsBgColor") {
      setCheckoutPageCardsBgColor(val);
    } else if (field === "checkoutPageFontColor") {
      setCheckoutPageFontColor(val);
    } else if (field === "checkoutPagePaymentBtnBgColor") {
      setCheckoutPagePaymentBtnBgColor(val);
    } else if (field === "checkoutPagePaymentBtnFontColor") {
      setCheckoutPagePaymentBtnFontColor(val);
    }
  };

  const handlerOnInputChange = (field, val) => {
    if (field === "menuDetailPopupModifierPriceFontSize") {
      setMenuDetailPopupModifierPriceFontSize(val.replace(/[^0-9.]/g, ""));
    } else if (field === "menuDetailPopupItemPriceFontSize") {
      setMenuDetailPopupItemPriceFontSize(val.replace(/[^0-9.]/g, ""));
    } else if (field === "checkoutPageBillingPriceFontSize") {
      setCheckoutPageBillingPriceFontSize(val.replace(/[^0-9.]/g, ""));
    } else if (field === "checkoutPageBillingTotalPriceFontSize") {
      setCheckoutPageBillingTotalPriceFontSize(val.replace(/[^0-9.]/g, ""));
    } else if (field === "cartPageCardHeaderFontSize") {
      setCartPageCardHeaderFontSize(val.replace(/[^0-9.]/g, ""));
    } else if (field === "cartPageCardItemNameFontSize") {
      setCartPageCardItemNameFontSize(val.replace(/[^0-9.]/g, ""));
    } else if (field === "cartPageCardItemPriceFontSize") {
      setCartPageCardItemPriceFontSize(val.replace(/[^0-9.]/g, ""));
    }
  };

  const ToggleSwitchLabel = ({ label, info }) => {
    return (
      <Typography
        className={`${styles?.["toggle-switch-label"]}`}
        sx={{ path: { fill: "#000" }, mr: 1 }}
      >
        {label}
        {info ? (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  marginTop: "0px !important",
                  marginBottom: "0px !important",
                  backgroundColor: "#EEE",
                  color: "#000",
                },
              },
            }}
            title={info}
            placement="top"
          >
            <InfoIcon
              height={15}
              width={15}
              style={{ cursor: "pointer", marginLeft: 4 }}
            />
          </Tooltip>
        ) : null}
      </Typography>
    );
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {loading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <>
          <Grid container item xs={12}>
            <Grid item xs={12} mb={2}>
              <RestaurantHeader
                title={"Portal Settings"}
                onBackBtnClick={() => navigate("/restaurant/" + restaurantId)}
              />
            </Grid>
            <Grid item xs={12} className={styles.mainView} px={3} pt={2}>
              <Grid
                mt={2}
                container
                direction="row"
                item
                xs={12}
                md={12}
                mb={2}
              >
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"printLogo"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={printLogo}
                    imageType={imageType}
                    placeholder={"PRINT LOGO"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("printLogo")}
                    info={InfoList?.printLogo}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"headerBg"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={headerBg}
                    imageType={imageType}
                    placeholder={"MAIN HEADER BG"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("headerBg")}
                    info={InfoList?.mainHeaderBg}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"menuHeaderBg"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={menuHeaderBg}
                    imageType={imageType}
                    placeholder={"HEADER BG"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("menuHeaderBg")}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"arModelBg"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={arModelBg}
                    imageType={imageType}
                    placeholder={"AR MODEL BG"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("arModelBg")}
                    info={InfoList?.arModelBg}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"bottomMenuIcon"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={bottomMenuIcon}
                    imageType={imageType}
                    placeholder={"BOTTOM MENU ICON"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("bottomMenuIcon")}
                    info={InfoList?.bottomMenuIcon}
                  />
                </Box>

                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"menuSectionBg"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={menuSectionBgVal}
                    imageType={imageType}
                    placeholder={"MENU SECTION IMG"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("menuSectionBg")}
                    info={InfoList?.menuSectionBg}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"prevIcon"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={prevIconVal}
                    imageType={imageType}
                    placeholder={"PREV ICON"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("prevIcon")}
                    info={InfoList?.prevIcon}
                  />
                </Box>
                <Box mr={2} mb={1}>
                  <UploadLogo
                    type={"nextIcon"}
                    uploadFile={uploadLogoFile}
                    imageLoading={imageLoading}
                    logoImg={nextIconVal}
                    imageType={imageType}
                    placeholder={"NEXT ICON"}
                    showViewUploadIcon={true}
                    onRemove={() => handlerOnRemoveImg("nextIcon")}
                    info={InfoList?.nextIcon}
                  />
                </Box>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container alignItems="center" mt={1} mb={3}>
                <Box mt={2} display="flex" alignItems="center" mr={4}>
                  <ToggleSwitchLabel
                    label={"Online Order"}
                    info={InfoList?.onlineOrder}
                  />
                  <SwitchInput
                    checked={onlineOrder}
                    onChange={setOnlineOrder}
                  />
                </Box>
                <Box mt={2} display="flex" alignItems="center" mr={4}>
                  <ToggleSwitchLabel
                    label={"Table Order"}
                    info={InfoList?.tableOrder}
                  />
                  <SwitchInput checked={tableOrder} onChange={setTableOrder} />
                </Box>
                <Box mt={2} display="flex" alignItems="center" mr={4}>
                  <ToggleSwitchLabel
                    label={"Table Payment"}
                    info={InfoList?.tablePayment}
                  />
                  <SwitchInput
                    checked={tablePayment}
                    onChange={setTablePayment}
                  />
                </Box>
                <Box mt={2} display="flex" alignItems="center">
                  <ToggleSwitchLabel
                    label={"Wifi Available"}
                    info={InfoList?.wifiAvailable}
                  />
                  <SwitchInput
                    checked={wifiAvailable}
                    onChange={setWifiAvailable}
                  />
                </Box>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid container item xs={12}>
                <Grid item xs={12} md={5.8} mt={2} mb={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      App Type
                    </Typography>
                  </Box>
                  <Box>
                    <SettingSelectField
                      page={"customRestaurantInfo"}
                      value={appType}
                      setValue={setAppType}
                      border={"1px solid #AAA"}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      menuList={[
                        APP_TYPE_FOODO,
                        APP_TYPE_GOPOSH,
                        APP_TYPE_BOTH,
                      ]}
                      error={error?.appType}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid
                mt={2}
                container
                direction="row"
                justifyContent={"space-between"}
                item
                xs={12}
                md={12}
                mb={2}
              >
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Menu Item Font Color
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={menuItemFontColor}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, menuItemFontColor: "" });
                        } else {
                          setError({
                            ...error,
                            menuItemFontColor: "Invalid Color Code",
                          });
                        }
                        setMenuItemFontColor(val);
                      }}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      border={"1px solid #AAA"}
                      error={error?.menuItemFontColor}
                      leftView={<ColorView color={menuItemFontColor} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Menu Item Font Bg
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={menuItemFontBg}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, menuItemFontBg: "" });
                        } else {
                          setError({
                            ...error,
                            menuItemFontBg: "Invalid Color Code",
                          });
                        }
                        setMenuItemFontBg(val);
                      }}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      border={"1px solid #AAA"}
                      error={error?.menuItemFontBg}
                      leftView={<ColorView color={menuItemFontBg} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Menu Item Font Name
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={menuItemFontName}
                      setValue={(val) => setMenuItemFontName(val)}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      border={"1px solid #AAA"}
                      error={error?.menuItemFontName}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Menu Item Font File
                    </Typography>
                  </Box>
                  <Box>
                    <SettingFileInput
                      id={"menuFontFileButton"}
                      type={"menuItemFontFile"}
                      value={menuItemFontFile?.name}
                      setValue={() => null}
                      uploadFile={fileUploadApi}
                      border={"1px solid #AAA"}
                      error={error?.menuItemFontFile}
                      onRemove={() => setMenuItemFontFile("")}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid
                mt={2}
                container
                direction="row"
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                item
                xs={12}
                md={12}
                mb={2}
              >
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Modal Bg Color
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={modalBgColor}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, modalBgColor: "" });
                        } else {
                          setError({
                            ...error,
                            modalBgColor: "Invalid Color Code",
                          });
                        }
                        setModalBgColor(val);
                      }}
                      border={"1px solid #AAA"}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      error={error?.modalBgColor}
                      leftView={<ColorView color={modalBgColor} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Modal Font Color
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={modalFontColor}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, modalFontColor: "" });
                        } else {
                          setError({
                            ...error,
                            modalFontColor: "Invalid Color Code",
                          });
                        }
                        setModalFontColor(val);
                      }}
                      border={"1px solid #AAA"}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      error={error?.modalFontColor}
                      leftView={<ColorView color={modalFontColor} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      User Form Data Bg
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={userFormDataBg}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, userFormDataBg: "" });
                        } else {
                          setError({
                            ...error,
                            userFormDataBg: "Invalid Color Code",
                          });
                        }
                        setUserFormDataBg(val);
                      }}
                      border={"1px solid #AAA"}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      error={error?.userFormDataBg}
                      leftView={<ColorView color={userFormDataBg} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      User Form Data Label Color
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={userFormDataLabelColor}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, userFormDataLabelColor: "" });
                        } else {
                          setError({
                            ...error,
                            userFormDataLabelColor: "Invalid Color Code",
                          });
                        }
                        setUserFormDataLabelColor(val);
                      }}
                      border={"1px solid #AAA"}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      error={error?.userFormDataLabelColor}
                      leftView={<ColorView color={userFormDataLabelColor} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      User Form Data Font Color
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={userFormDataFontColor}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, userFormDataFontColor: "" });
                        } else {
                          setError({
                            ...error,
                            userFormDataFontColor: "Invalid Color Code",
                          });
                        }
                        setUserFormDataFontColor(val);
                      }}
                      border={"1px solid #AAA"}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      error={error?.userFormDataFontColor}
                      leftView={<ColorView color={userFormDataFontColor} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Button Bg
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={buttonBg}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, buttonBg: "" });
                        } else {
                          setError({
                            ...error,
                            buttonBg: "Invalid Color Code",
                          });
                        }
                        setButtonBg(val);
                      }}
                      border={"1px solid #AAA"}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      error={error?.buttonBg}
                      leftView={<ColorView color={buttonBg} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Button Text Bg
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={buttonTextBg}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, buttonTextBg: "" });
                        } else {
                          setError({
                            ...error,
                            buttonTextBg: "Invalid Color Code",
                          });
                        }
                        setButtonTextBg(val);
                      }}
                      border={"1px solid #AAA"}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      error={error?.buttonTextBg}
                      leftView={<ColorView color={buttonTextBg} />}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid
                mt={2}
                container
                direction="row"
                justifyContent={"space-between"}
                item
                xs={12}
                md={12}
                mb={2}
              >
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Category Underline Color
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={categoryUnderlineColor}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, categoryUnderlineColor: "" });
                        } else {
                          setError({
                            ...error,
                            categoryUnderlineColor: "Invalid Color Code",
                          });
                        }
                        setCategoryUnderlineColor(val);
                      }}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      border={"1px solid #AAA"}
                      error={error?.categoryUnderlineColor}
                      leftView={<ColorView color={categoryUnderlineColor} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Item Price Font Color
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={itemPriceFontColor}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, itemPriceFontColor: "" });
                        } else {
                          setError({
                            ...error,
                            itemPriceFontColor: "Invalid Color Code",
                          });
                        }
                        setItemPriceFontColor(val);
                      }}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      border={"1px solid #AAA"}
                      error={error?.itemPriceFontColor}
                      leftView={<ColorView color={itemPriceFontColor} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Item Price Bg Color
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={itemPriceBgColor}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, itemPriceBgColor: "" });
                        } else {
                          setError({
                            ...error,
                            itemPriceBgColor: "Invalid Color Code",
                          });
                        }
                        setItemPriceBgColor(val);
                      }}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      border={"1px solid #AAA"}
                      error={error?.itemPriceBgColor}
                      leftView={<ColorView color={itemPriceBgColor} />}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Ar View Bg Color
                    </Typography>
                  </Box>
                  <Box>
                    <SettingTextField
                      page={"customRestaurantInfo"}
                      editable={true}
                      value={arViewBgColor}
                      setValue={(val) => {
                        if (colorValidation(val) || val == "") {
                          setError({ ...error, arViewBgColor: "" });
                        } else {
                          setError({
                            ...error,
                            arViewBgColor: "Invalid Color Code",
                          });
                        }
                        setArViewBgColor(val);
                      }}
                      placeholder={""}
                      required={true}
                      variant={"filled"}
                      border={"1px solid #AAA"}
                      error={error?.arViewBgColor}
                      leftView={<ColorView color={arViewBgColor} />}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid
                container
                direction="row"
                justifyContent={"space-between"}
                item
                xs={12}
                md={12}
                my={2}
              >
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Menu Listing Page
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Bg Color"}
                    field={"menuListBgColor"}
                    value={menuListBgColor}
                    error={error?.menuListBgColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Font Color"}
                    field={"menuListFontColor"}
                    value={menuListFontColor}
                    error={error?.menuListFontColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Category Font Color"}
                    field={"menuListCatFontColor"}
                    value={menuListCatFontColor}
                    error={error?.menuListCatFontColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Category Bg Color"}
                    field={"menuListCatBgColor"}
                    value={menuListCatBgColor}
                    error={error?.menuListCatBgColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Selected Category Font Color"}
                    field={"menuListSelectedCatFontColor"}
                    value={menuListSelectedCatFontColor}
                    error={error?.menuListSelectedCatFontColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Selected Category Bg Color"}
                    field={"menuListSelectedCatBgColor"}
                    value={menuListSelectedCatBgColor}
                    error={error?.menuListSelectedCatBgColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid
                container
                item
                xs={12}
                md={12}
                direction="row"
                justifyContent={"space-between"}
                my={2}
              >
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Menu Detail Popup
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Item Font Color"}
                    field={"menuDetailPopupItemFontColor"}
                    value={menuDetailPopupItemFontColor}
                    error={error?.menuDetailPopupItemFontColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Item Bg Color"}
                    field={"menuDetailPopupItemBgColor"}
                    value={menuDetailPopupItemBgColor}
                    error={error?.menuDetailPopupItemBgColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Item Price Font Size (Default 18px)
                    </Typography>
                  </Box>
                  <SettingTextField
                    page={"customRestaurantInfo"}
                    editable={true}
                    value={menuDetailPopupItemPriceFontSize}
                    setValue={(val) =>
                      handlerOnInputChange(
                        "menuDetailPopupItemPriceFontSize",
                        val
                      )
                    }
                    placeholder={""}
                    required={true}
                    variant={"filled"}
                    border={"1px solid #AAA"}
                    error={error?.menuDetailPopupItemPriceFontSize}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Modifier Font Color"}
                    field={"menuDetailPopupModifierFontColor"}
                    value={menuDetailPopupModifierFontColor}
                    error={error?.menuDetailPopupModifierFontColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Modifier Bg Color"}
                    field={"menuDetailPopupModifierBgColor"}
                    value={menuDetailPopupModifierBgColor}
                    error={error?.menuDetailPopupModifierBgColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Modifier Price Font Size (Default 14px)
                    </Typography>
                  </Box>
                  <SettingTextField
                    page={"customRestaurantInfo"}
                    editable={true}
                    value={menuDetailPopupModifierPriceFontSize}
                    setValue={(val) =>
                      handlerOnInputChange(
                        "menuDetailPopupModifierPriceFontSize",
                        val
                      )
                    }
                    placeholder={""}
                    required={true}
                    variant={"filled"}
                    border={"1px solid #AAA"}
                    error={error?.menuDetailPopupModifierPriceFontSize}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Add To Cart Btn Bg Color"}
                    field={"menuDetailPopupAddToCartBtnBgColor"}
                    value={menuDetailPopupAddToCartBtnBgColor}
                    error={error?.menuDetailPopupAddToCartBtnBgColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Add To Cart Btn Font Color"}
                    field={"menuDetailPopupAddToCartBtnFontColor"}
                    value={menuDetailPopupAddToCartBtnFontColor}
                    error={error?.menuDetailPopupAddToCartBtnFontColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid
                container
                direction="row"
                justifyContent={"space-between"}
                item
                xs={12}
                md={12}
                my={2}
              >
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Cart Page
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Bg Color"}
                    field={"cartPageBgColor"}
                    value={cartPageBgColor}
                    error={error?.cartPageBgColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Card Header Font Size (Default 15px)
                    </Typography>
                  </Box>
                  <SettingTextField
                    page={"customRestaurantInfo"}
                    editable={true}
                    value={cartPageCardHeaderFontSize}
                    setValue={(val) =>
                      handlerOnInputChange("cartPageCardHeaderFontSize", val)
                    }
                    placeholder={""}
                    required={true}
                    variant={"filled"}
                    border={"1px solid #AAA"}
                    error={error?.cartPageCardHeaderFontSize}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Card Item Name Font Size (Default 14px)
                    </Typography>
                  </Box>
                  <SettingTextField
                    page={"customRestaurantInfo"}
                    editable={true}
                    value={cartPageCardItemNameFontSize}
                    setValue={(val) =>
                      handlerOnInputChange("cartPageCardItemNameFontSize", val)
                    }
                    placeholder={""}
                    required={true}
                    variant={"filled"}
                    border={"1px solid #AAA"}
                    error={error?.cartPageCardItemNameFontSize}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Card Item Price Font Size (Default 14px)
                    </Typography>
                  </Box>
                  <SettingTextField
                    page={"customRestaurantInfo"}
                    editable={true}
                    value={cartPageCardItemPriceFontSize}
                    setValue={(val) =>
                      handlerOnInputChange("cartPageCardItemPriceFontSize", val)
                    }
                    placeholder={""}
                    required={true}
                    variant={"filled"}
                    border={"1px solid #AAA"}
                    error={error?.cartPageCardItemPriceFontSize}
                  />
                </Grid>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid
                container
                direction="row"
                justifyContent={"space-between"}
                item
                xs={12}
                md={12}
                my={2}
              >
                <Grid item xs={12} mt={2}>
                  <Typography className={styles.textInputLabel}>
                    Checkout Page
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Bg Color"}
                    field={"checkoutPageBgColor"}
                    value={checkoutPageBgColor}
                    error={error?.checkoutPageBgColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Cards Bg Color"}
                    field={"checkoutPageCardsBgColor"}
                    value={checkoutPageCardsBgColor}
                    error={error?.checkoutPageCardsBgColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Font Color"}
                    field={"checkoutPageFontColor"}
                    value={checkoutPageFontColor}
                    error={error?.checkoutPageFontColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Payment Button Bg Color"}
                    field={"checkoutPagePaymentBtnBgColor"}
                    value={checkoutPagePaymentBtnBgColor}
                    error={error?.checkoutPagePaymentBtnBgColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <PortalSettingsColorInput
                    label={"Payment Button Font Color"}
                    field={"checkoutPagePaymentBtnFontColor"}
                    value={checkoutPagePaymentBtnFontColor}
                    error={error?.checkoutPagePaymentBtnFontColor}
                    handlerOnColorChange={handlerOnColorChange}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Billing Price Font Size (Default 16px)
                    </Typography>
                  </Box>
                  <SettingTextField
                    page={"customRestaurantInfo"}
                    editable={true}
                    value={checkoutPageBillingPriceFontSize}
                    setValue={(val) =>
                      handlerOnInputChange(
                        "checkoutPageBillingPriceFontSize",
                        val
                      )
                    }
                    placeholder={""}
                    required={true}
                    variant={"filled"}
                    border={"1px solid #AAA"}
                    error={error?.checkoutPageBillingPriceFontSize}
                  />
                </Grid>
                <Grid item xs={12} md={5.8} mt={2}>
                  <Box mb={1}>
                    <Typography className={styles.textInputLabel}>
                      Billing Total Price Font Size (Default 19px)
                    </Typography>
                  </Box>
                  <SettingTextField
                    page={"customRestaurantInfo"}
                    editable={true}
                    value={checkoutPageBillingTotalPriceFontSize}
                    setValue={(val) =>
                      handlerOnInputChange(
                        "checkoutPageBillingTotalPriceFontSize",
                        val
                      )
                    }
                    placeholder={""}
                    required={true}
                    variant={"filled"}
                    border={"1px solid #AAA"}
                    error={error?.checkoutPageBillingTotalPriceFontSize}
                  />
                </Grid>
              </Grid>

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    borderColor: "#DDD",
                  },
                }}
              />

              <Grid item container xs={12} mt={3}>
                <Typography className={styles.textInputLabel}>
                  Social Media
                </Typography>
              </Grid>
              <Grid
                mt={1}
                container
                direction="row"
                justifyContent={"space-between"}
                sx={{ border: "1px solid #AAA", borderRadius: 5 }}
                item
                xs={12}
                md={12}
                mb={2}
                py={2}
                px={3}
              >
                {socialMediaList.map((x, i) => {
                  return (
                    <SocialMedia
                      key={i}
                      index={i}
                      data={x}
                      imageLoading={imageLoading}
                      imageType={imageType}
                      uploadLogoFile={uploadLogoFile}
                      getImgDetail={getImgDetail}
                      onUpdate={onUpdateSocialMedia}
                      onDelete={onDeleteSocialMedia}
                    />
                  );
                })}
              </Grid>
              <Grid container item xs={12} justifyContent={"flex-end"} mt={-1}>
                <Button
                  type="button"
                  disabled={false}
                  className={styles.addEntry}
                  onClick={onAddEntry}
                >
                  Add Entry
                </Button>
              </Grid>
              <Grid container direction="row" item xs={12} md={12} mb={2}>
                <Button
                  type="button"
                  disabled={saveBtnDisabled || imageLoading}
                  className={styles.saveBtn}
                  sx={{
                    opacity: saveBtnDisabled || imageLoading ? 0.2 : 1,
                  }}
                  onClick={saveBtnDisabled || imageLoading ? null : onSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

export default CustomRestaurantInfo;
