import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import styles from "./OrderHistory.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import Page from "../../components/Page";
import SettingDatePicker from "../../components/SettingDatePicker";
import Loader from "../../components/Loader";
import RestaurantHeader from "../../components/RestaurantHeader";

import { ReactComponent as CalenderIcon } from "../../assets/images/calender_gray.svg";

import { DELIVERY, DINEIN, PICKUP, TABLE } from "../../constants";

let FILTER_LIST = [
  { _id: "all", name: "All Orders" },
  { _id: "online", name: "Online Orders" },
  { _id: "dining", name: "Dining Orders" },
];

let DATE_FILTER_LIST = [
  { _id: "date", name: "" },
  { _id: "today", name: "Today" },
  { _id: "this_week", name: "This Week" },
  { _id: "this_month", name: "This Month" },
];

const dateFormat = "DD-MM-YYYY";

const OrderHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [orderTypeFilter, setOrderTypeFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState("");
  const [dateVal, setDateVal] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currencyVal, setCurrencyVal] = useState("");

  let orderTypeFilterBg = { true: "#000", false: "#FFF" };
  let orderTypeFilterText = {
    true: "#FFF",
    false: "#777777",
  };

  useEffect(() => {
    getOrderList(1);
  }, [orderTypeFilter, dateFilter, dateVal]);

  const getOrderList = (num) => {
    setIsLoading(true);

    let filter = `pageSize=20&pageNum=${num}&filter__idRestaurant=${restaurantId}&filter_orderStatus=["completed", "payment_complete"]&orderBy=createdAt&orderByDir=desc`;
    if (orderTypeFilter === "dining") {
      filter += `&filter_orderType=[${DINEIN},${TABLE}]`;
    } else if (orderTypeFilter === "online") {
      filter += `&filter_orderType=[${DELIVERY},${PICKUP}]`;
    } else {
      filter += `&filter_orderType=[${DINEIN},${TABLE},${DELIVERY},${PICKUP}]`;
    }

    if (dateFilter != "") {
      let fromDate = "";
      let toDate = "";
      if (dateFilter == "date" && dateVal != "") {
        fromDate = moment(dateVal, dateFormat).format("Y-MM-DD");
        toDate = moment(dateVal, dateFormat).add(1, "days").format("Y-MM-DD");
      } else if (dateFilter == "today") {
        fromDate = moment().format("Y-MM-DD");
        toDate = moment().add(1, "days").format("Y-MM-DD");
      } else if (dateFilter == "this_week") {
        fromDate = moment().startOf("week").format("Y-MM-DD");
        toDate = moment().endOf("week").add(1, "days").format("Y-MM-DD");
      } else if (dateFilter == "this_month") {
        fromDate = moment().startOf("month").format("Y-MM-DD");
        toDate = moment().endOf("month").add(1, "days").format("Y-MM-DD");
      }
      filter =
        filter +
        "&filter_dateRangeFrom=" +
        fromDate +
        "&filter_dateRangeTo=" +
        toDate;
    }

    Api.getOrders(filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setPageNum(num);
        setTotalPages(response.data.pages);

        let list = [];
        let currency = currencyVal;

        if (currency == "") {
          data
            .filter((c) => typeof c.cart?.[0]?.["price"] != "undefined")
            .map((x, i) => {
              let cart_filter = x.cart.filter(
                (c) => typeof c.price != "undefined"
              );
              if (cart_filter.length && currency == "") {
                currency = cart_filter[0]["price"]["currency"];
                setCurrencyVal(currency);
              }
            });
        }

        data.map((x, i) => {
          let list_item = {
            ...x,
            date: moment(x.createdAt).format("DD MMM Y, HH:mm A"),
            price: { value: x.totalAmount, currency: currency },
          };

          list.push(list_item);
        });
        let sortList = [];

        list.reverse().map((item, index) => {
          let date = moment(item.createdAt).format("DD MMM Y");
          let dataIndex = sortList.findIndex((x) => x?.date === date);
          if (dataIndex >= 0) {
            sortList[dataIndex]["list"] = [
              ...sortList[dataIndex]["list"],
              item,
            ];
          } else {
            sortList.push({ date: date, list: [item] });
          }
        });

        setOrderList(sortList);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const onSelectPage = (value) => {
    getOrderList(value);
  };

  const handlerDateChange = (val) => {
    setDateFilter("date");
    setDateVal(moment(val).format(dateFormat));
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <Grid container item xs={12}>
          <Grid
            container
            xs={12}
            mb={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <RestaurantHeader
              title={"Order History"}
              onBackBtnClick={() => navigate("/restaurant/" + restaurantId)}
            />
            <Box
              ml={2}
              className={styles.orderTypeFilterListView}
              style={{ backgroundColor: orderTypeFilterBg[false] }}
            >
              {FILTER_LIST.map((x, i) => {
                return (
                  <Box
                    key={i}
                    onClick={() => {
                      setOrderTypeFilter(x._id);
                      setDateFilter("");
                      setDateVal("");
                    }}
                    className={styles.orderTypeFilterView}
                    style={{
                      backgroundColor:
                        orderTypeFilterBg[x._id == orderTypeFilter],
                    }}
                  >
                    <Typography
                      className={styles.orderTypeFilterText}
                      style={{
                        color: orderTypeFilterText[x._id == orderTypeFilter],
                      }}
                    >
                      {x.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} className={styles.mainView} p={2} pt={3}>
            <Grid item xs={12} className={styles.dateFilterMainView}>
              <Box
                className={styles.orderDateFilterListView}
                style={{ backgroundColor: orderTypeFilterBg[false] }}
              >
                {DATE_FILTER_LIST.map((x, i) => {
                  return (
                    <Box key={i} className={styles.dateFilterMainView}>
                      <Box
                        onClick={() => {
                          if (x._id == "date") {
                          } else {
                            setDateFilter(x._id);
                            setDateVal("");
                          }
                        }}
                        className={styles.dateFilterView}
                        style={{
                          backgroundColor:
                            orderTypeFilterBg[x._id == dateFilter],
                        }}
                      >
                        {x._id == "date" &&
                        (dateFilter != "date" ||
                          (dateFilter == "date" && dateVal != "")) ? (
                          <Box
                            style={{
                              width: dateVal == "" ? 30 : 100,
                            }}
                          >
                            <SettingDatePicker
                              value={dateVal}
                              inputFormat={dateFormat}
                              onChange={handlerDateChange}
                              height={"34px"}
                              color={"#FFF"}
                              backgroundColor={
                                orderTypeFilterBg[x._id == dateFilter]
                              }
                              leftView={
                                <Box className="cursor-pointer">
                                  <CalenderIcon width={17} height={17} />
                                </Box>
                              }
                            />
                          </Box>
                        ) : (
                          <Typography
                            className={styles.orderTypeFilterText}
                            style={{
                              color: orderTypeFilterText[x._id == dateFilter],
                            }}
                          >
                            {x.name}
                          </Typography>
                        )}
                      </Box>
                      {i < DATE_FILTER_LIST.length - 1 ? (
                        <Box
                          style={{
                            height: "40%",
                            width: 1,
                            backgroundColor: "#C4C4C4",
                          }}
                        />
                      ) : null}
                    </Box>
                  );
                })}
              </Box>
            </Grid>
            <Grid item md={12} className={styles.orderList}>
              {orderList.map((t, i2) => {
                let order_list = t?.list.filter(
                  (x) =>
                    ([DELIVERY, PICKUP].includes(x.orderType) &&
                      orderTypeFilter === "online") ||
                    ([DINEIN, TABLE].includes(x.orderType) &&
                      orderTypeFilter === "dining") ||
                    orderTypeFilter === "all"
                );
                if (order_list.length == 0) {
                  return null;
                }
                return (
                  <Box mb={3} key={i2}>
                    <Typography className={styles.orderDateTitleText}>
                      {t.date == moment().format("DD MMM Y") ? "Today" : t.date}
                    </Typography>
                    <Box mt={1}>
                      {order_list.map((x, i) => {
                        return (
                          <Box
                            key={i}
                            className={styles.orderListView}
                            mb={2}
                            px={2}
                            py={1}
                          >
                            <Box className={styles.orderDataSubView}>
                              <Typography className={styles.orderIdText}>
                                Order #{x?._id?.toString().substr(-5)}
                              </Typography>
                              <Typography
                                mt={1}
                                className={styles.orderDateText}
                              >
                                {x?.date}
                              </Typography>
                            </Box>
                            <Box className={styles.orderSubView}>
                              <Box
                                className={styles.orderTypeView}
                                style={{
                                  backgroundColor:
                                    x.orderType == DELIVERY ||
                                    x.orderType == PICKUP
                                      ? "#960090"
                                      : "#1D86FF",
                                }}
                              >
                                <Typography className={styles.orderTypeText}>
                                  {[DELIVERY, PICKUP].includes(x.orderType)
                                    ? "Online Order"
                                    : x.orderType === 4
                                    ? "Table Order"
                                    : "Dine in"}
                                </Typography>
                              </Box>
                              {x.name ? (
                                <Typography
                                  mt={0.5}
                                  className={styles.orderNameText}
                                >
                                  Name: {x.name}
                                </Typography>
                              ) : null}
                            </Box>
                            <Box className={styles.orderPriceSubView}>
                              <Box className={styles.orderPriceView}>
                                <Typography className={styles.orderPriceText}>
                                  Price
                                </Typography>
                                <Typography
                                  mt={1}
                                  className={styles.orderPriceValText}
                                >
                                  {x.price?.currency} {x.price?.value}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Grid>
            <Grid container mt={3} item xs={12} justifyContent={"center"}>
              {totalPages > 0 ? (
                <Page
                  totalPages={totalPages}
                  pageNum={pageNum}
                  onSelectPage={onSelectPage}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default OrderHistory;
