import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import SettingTextField from "./SettingTextField";

import useDebounce from "./UseDebounce";

const PortalSettingsColorInput = (props) => {
  const { label, value, field, error = "" } = props;

  const [colorVal, setColorVal] = useState(value);

  const debouncedValue = useDebounce(colorVal, 500);

  useEffect(() => {
    props.handlerOnColorChange(field, colorVal);
  }, [debouncedValue]);

  const handlerOnColorChange = (val) => {
    setColorVal(val);
  };

  return (
    <>
      <Box mb={1}>
        <Typography
          sx={{
            color: "#000000",
            fontSize: 14.5,
            fontFamily: "GilroySemiBold !important",
          }}
        >
          {label}
        </Typography>
      </Box>
      <Box>
        <SettingTextField
          page={"customRestaurantInfo"}
          editable={true}
          value={colorVal}
          setValue={handlerOnColorChange}
          placeholder={""}
          required={true}
          variant={"filled"}
          border={"1px solid #AAA"}
          error={error}
          leftView={
            <Box
              sx={{
                width: 18,
                height: 18,
                border: "0.5px solid #AAA",
                backgroundColor: colorVal,
              }}
            />
          }
        />
      </Box>
    </>
  );
};

export default PortalSettingsColorInput;
