import React, { useCallback, useEffect, useState } from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { ReactComponent as EditIcon } from "./../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/images/delete1.svg";
import { ReactComponent as FileIcon } from "./../../assets/images/file.svg";
import { ReactComponent as EyeSlashIcon } from "./../../assets/images/eye_slash.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#FFF",
    fontFamily: "GilroyBold",
    fontSize: 14,
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#000000",
    fontFamily: "GilroyMedium",
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F5F5F5",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SettingCategoryList = (props) => {
  let data = props.categoryList;

  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: 5, boxShadow: "0px 4px 10px 0px #E5E5E5" }}
      elevation={0}
    >
      <Table aria-label="customized table">
        <TableHead sx={{ backgroundColor: "#000" }}>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell sx={{ borderBottom: "none" }}>
              <Box>Category Title</Box>
            </StyledTableCell>

            <StyledTableCell sx={{ borderBottom: "none" }}>
              <Box>Description</Box>
            </StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                <Box sx={styles.imageBox}>
                  {item.images.length > 0 ? (
                    <img
                      src={item.images[0]}
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <FileIcon height={30} width={30} />
                  )}
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={styles.nameBox}>
                  {item.name}
                  {!item?.enabled && (
                    <EyeSlashIcon
                      width={20}
                      height={20}
                      style={{ marginLeft: 8 }}
                    />
                  )}
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={styles.descBox}>{item.description}</Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={styles.actionBox}>
                  <EditIcon
                    height={20}
                    width={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => props.editCategory(item)}
                  />
                  <DeleteIcon
                    height={20}
                    width={20}
                    style={{ marginLeft: 10, cursor: "pointer" }}
                    onClick={() => props.deleteCategory(item)}
                  />
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow sx={{ height: 70 }} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SettingCategoryList;

const styles = {
  imageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nameBox: {
    minWidth: 120,
    display: "flex",
    alignItems: "center",
  },
  descBox: {},
  actionBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  arrowIconBox: {
    float: "right",
    marginRight: 5,
  },
  arrowIcon: {
    cursor: "pointer",
    lineHeight: 0,
  },
};
