import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { SortableKnob } from "react-easy-sort";
import Popover from "@mui/material/Popover";

import { ReactComponent as ExpandVerticalIcon } from "../../assets/images/expand_vertical.svg";
import { ReactComponent as EditIcon } from "../../assets/images/pencil.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete2.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/images/eye_slash.svg";

import "../../assets/css/custom.css";

import { CURRENCY_SYMBOL, THEME_COLOR2 } from "../../constants";

const symbol = CURRENCY_SYMBOL;

const MenuListItem = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onMenuClick = (event) => {
    if (props.showMenuPopup) {
      if (anchorEl == null) {
        setAnchorEl(event.currentTarget);
      } else {
        setAnchorEl(null);
      }
    } else {
      props.onSelect(props.data);
    }
  };

  const handlePopoverClose = (event) => {
    setAnchorEl(null);
  };

  let data = props.data;

  return (
    <Grid
      className="menu-list-main-grid"
      style={{ boxShadow: "0px 0px 15px 0px #00000014" }}
      onMouseLeave={handlePopoverClose}
    >
      <Grid
        container
        sx={{ position: "relative", cursor: "pointer" }}
        onClick={onMenuClick}
      >
        {!props.allowSort ? null : (
          <SortableKnob>
            <Grid
              item
              xs={1.5}
              md={1.3}
              className="menu-list-expand-vertical-icon-grid"
            >
              <ExpandVerticalIcon height={30} width={30} />
            </Grid>
          </SortableKnob>
        )}

        <Grid item xs={3.5} md={1.2} className="menu-list-image-grid">
          <img src={data.images[0]} className="menu-list-image" />
        </Grid>

        <Grid
          item
          xs={7}
          md={9.5}
          sm
          container
          direction="row"
          className="menu-list-main-data-grid"
        >
          <Grid item xs={12} md={4} className="menu-list-sub-data-grid">
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              sx={styles.nameText}
            >
              {data.name}
              {!data?.enabled && (
                <EyeSlashIcon
                  width={20}
                  height={20}
                  style={{ marginLeft: 8 }}
                />
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} md={2} className="menu-list-price-data-grid">
            <Typography
              variant="subtitle1"
              sx={styles.priceText}
              component="div"
            >
              {symbol[data.price.currency]}
              {data.price.value}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} className="menu-list-sub-data-grid">
            <Typography variant="body2" sx={styles.descText}>
              {data.description}
            </Typography>
          </Grid>
        </Grid>

        <Popover
          sx={{ zIndex: 11 }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <Paper
            sx={{
              flexDirection: "column",
              display: "flex",
              alignItems: "flex-start",
            }}
            onMouseLeave={handlePopoverClose}
          >
            <Button
              onClick={() => props.editMenu(data)}
              sx={[styles.btnText, styles.editBtnText]}
            >
              <EditIcon />
              <Typography px={2} py={1}>
                Edit
              </Typography>
            </Button>
            <Button
              onClick={() => props.deleteMenu(data)}
              sx={[styles.btnText, styles.deleteBtnText]}
            >
              <DeleteIcon />
              <Typography px={2} py={1}>
                Delete
              </Typography>
            </Button>
          </Paper>
        </Popover>
      </Grid>
    </Grid>
  );
};

export default MenuListItem;

const styles = {
  nameText: {
    fontFamily: "GilroyBold",
    fontSize: 15,
    display: "flex",
    alignItems: "center",
  },
  descText: {
    color: "#888",
    fontFamily: "GilroyRegular",
    fontSize: 13,
    textAlign: "left",
  },
  priceText: {
    fontFamily: "GilroyBold",
    fontSize: 17,
  },
  btnText: {
    px: 2,
    minWidth: 130,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#fff !important",
    },
  },
  editBtnText: {
    color: "#000",
  },
  deleteBtnText: {
    color: "#FE724C",
  },
};
