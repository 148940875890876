import React from 'react';
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {THEME_COLOR, THEME_COLOR4} from "../../constants";

const RestaurantMenubar = (props) => {
  let {page, restaurantId} = props;
  const navigate = useNavigate();

  return (
    <Box sx={styles.headerMenuDiv} mb={1}>
      <Box
        sx={{...styles.menuBtnView, backgroundColor: `${page == 'restaurantInfo' ? THEME_COLOR : 'transparent'}`}}
        onClick={() => navigate('/restaurant/' + restaurantId + '/info')}>
        <Typography sx={styles.menuBtnText}>Restaurant Info</Typography>
      </Box>
      <Box sx={{...styles.menuBtnView, backgroundColor: `${page == 'menu' ? THEME_COLOR : 'transparent'}`}}
           onClick={() => navigate('/restaurant/' + restaurantId + '/menu')}>
        <Typography sx={styles.menuBtnText}>Menu</Typography>
      </Box>
      <Box sx={{...styles.menuBtnView, backgroundColor: `${page == 'events' ? THEME_COLOR : 'transparent'}`}}
           onClick={() => navigate('/restaurant/' + restaurantId + '/events')}>
        <Typography sx={styles.menuBtnText}>Events</Typography>
      </Box>
      <Box sx={{...styles.menuBtnView, backgroundColor: `${page == 'settings' ? THEME_COLOR : 'transparent'}`}}
           onClick={() => navigate('/restaurant/' + restaurantId + '/settings')}>
        <Typography sx={styles.menuBtnText}>Settings</Typography>
      </Box>
    </Box>
  );
}

export default RestaurantMenubar;

const styles = {
  headerMenuDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuBtnView: {
    border: `1px solid ${THEME_COLOR4}`,
    cursor: 'pointer',
    borderRadius: 2,
    px: 1.5,
    py: 0.8,
    mr: 1
  },
  menuBtnText: {
    color: '#000',
    fontSize: 15,
    fontFamily: 'GilroyBold',
    marginLeft: 1,
  },
}
